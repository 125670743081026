import { Component, OnInit, HostListener } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {


  sidenavMode: string;
  sidenavOpened: boolean = false;
  sidenavAlign: string;
  showHeader: boolean = true;
  showFooter: boolean = true;
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.observerMQ();
  }
  constructor(
    protected observableMedia: ObservableMedia,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
      this.observerMQ();
      setTimeout(() => {
        this.openDialog()
      }, 500);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewDialog, {
      width: this.observableMedia.isActive('gt-sm') ? '495px' : '300px',
    });
  }

  observerMQ(): void {
    if (this.observableMedia.isActive('gt-sm')) {
      this.sidenavMode = 'side';
      this.sidenavOpened = true;
      this.sidenavAlign = 'end';
    } else {
      this.sidenavOpened = false;
      this.sidenavMode = 'push';
      this.sidenavAlign = 'end';
    }
  }
}


@Component({
  selector: 'app-dialog-pop-up',
  templateUrl: 'app-dialog-pop-up.html',
  styleUrls: ['./app.component.sass']
})
export class DialogOverviewDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewDialog>,
    ) {

    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}