 <nav class="l-header_mobileMenu">
    <mat-list>
        <mat-list-item *ngIf="isLoggedIn" routerLink="/minha-conta/meus-dados">
            <i class="fa fa-user-circle l-header_mobileMenu_fa"></i>
            Minha conta
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item *ngIf="isLoggedIn" routerLink="/minha-conta/meus-creditos">
            <i class="fa fa-money l-header_mobileMenu_fa"></i>
            {{(authStore$ | async)?.totalCredits || 0}} Créditos
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item *ngIf="isLoggedIn" (click)="logOut()">
            <i class="fa fa-sign-out l-header_mobileMenu_fa"></i>
            Sair
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item *ngIf="!isLoggedIn" routerLink="/login">
            <i class="fa fa-user-circle l-header_mobileMenu_fa"></i>
            Entrar
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item *ngIf="!isLoggedIn" routerLink="/login">
            <i class="fa fa-pencil l-header_mobileMenu_fa"></i>
            Inscreva-se
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-divider></mat-divider>
        
        <mat-list-item (click)="navigator('#estudio')">
            Estudio
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item [routerLink]="['/atividade/ciclismo-indoor/1']">
            Atividades
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item (click)="navigator('#instrutores')">
            Instrutores
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item [routerLink]="['/pacotes']">
            Pacotes
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item routerLink="/agenda">
            Agenda
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item routerLink="/faq">
            Perguntas Frequentes
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

        <mat-list-item (click)="navigator('#contato')">
            Contato
            <mat-icon mat-list-icon class="l-header_mobileMenu_mdlisticon">keyboard_arrow_right</mat-icon>
        </mat-list-item>

    </mat-list>
</nav>




