import { ScrollToService } from 'ng2-scroll-to-el';
import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { Ae2CarouselConfig, Ae2CarouselAnimations } from '@angularecommerce/core/components/carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  carouselConfig: Ae2CarouselConfig;
  estudiocarouselConfig: Ae2CarouselConfig;
  turnOn: boolean = false;
  title: string = 'My first angular2-google-maps project';
  lat: number = -23.592452;
  lng: number = -46.681242;
  libera: boolean = false;
  isVideo: boolean = true;

  constructor(private router: Router, private scrollService: ScrollToService) {
    this.carouselConfig = new Ae2CarouselConfig;
    // this.carouselConfig.xsHeight = '350px';
    // this.carouselConfig.smHeight = '350px';
    // this.carouselConfig.mdHeight = '350px';
    // this.carouselConfig.lgHeight = '350px';
    this.carouselConfig.useDottedOverLay = false;

    this.estudiocarouselConfig = new Ae2CarouselConfig();
    // this.estudiocarouselConfig.xsHeight = 'auto';
    // this.estudiocarouselConfig.smHeight = 'auto';
    // this.estudiocarouselConfig.mdHeight = 'auto';
    // this.estudiocarouselConfig.lgHeight = 'auto';

    const bannerSize = {
      xs: '375x210',
      sm: '767x1024',
      md: '767x1024',
      lg: '640x360'
    };

    const ratios = {
      xs: '375:210',
      sm: '3:4',
      md: '3:4',
      lg: '16:9'
    };

    this.estudiocarouselConfig.bannerSize = bannerSize;
    this.estudiocarouselConfig.ratios = ratios;
    this.estudiocarouselConfig.useDottedOverLay = false;
    this.estudiocarouselConfig.intervalTime = 4000;
    this.estudiocarouselConfig.animation = Ae2CarouselAnimations.bounceOutLeftInRight;
    this.estudiocarouselConfig.animationTime = 1;

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  volume(): any {
    this.turnOn = !this.turnOn;
  }

  liberaMapa(): any {
    this.libera = true;
  }

  filterRedirect(id: any): void {
    sessionStorage.setItem('activity-redirect', id);
    //    window.location.href  = '/agenda';
  }

  navigator(element: string): void {
    if (this.router.url === '/') {
      setTimeout(() => {
        this.scrollService.scrollTo(`${element}`);
      }, 1);
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        this.scrollService.scrollTo(`${element}`);
      }, 2000);
    }
  }

  onCarouselData(event: any): void {
    setTimeout(() => {
      if (!event) {
        return;
      }

      if (!event.count) {
        this.isVideo = false;
      }
    })
  }

  quebra(text: string): any {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

}
