import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaleriaRoutingModule } from './galeria-routing.module';
import { GaleriaComponent } from './galeria.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Ae2ActivityListContainerModule } from '@angularecommerce/core/components/activity-list-container';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';

@NgModule({
  imports: [
    CommonModule,
    GaleriaRoutingModule,
    Ae2ThumborModule,
    CommonModule,
    Ae2ActivityListContainerModule,
    FlexLayoutModule
  ],
  declarations: [GaleriaComponent],
  exports: [GaleriaComponent]
})
export class GaleriaModule { }
