import {
  Ae2LoginInPageStrategy, Ae2LoginStrategy
} from '@angularecommerce/core/services/login-strategy';
import { LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent, DialogOverviewDialog } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { Ae2CoreModule } from '@angularecommerce/core/modules/core';
import { Ae2AuthVerificationInitializerModule } from '@angularecommerce/core/modules/auth-verification-initializer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Ae2AuthStoreModule } from '@angularecommerce/core/stores/auth';
import { MenuResponsiveModule } from './shared/components/menu/responsive/responsive.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule, MatSidenavModule } from '@angular/material';
import { Ae2MyAccountGlobalConfig, MenuInterface, AE2_MY_ACCOUNT_CONFIG_TOKEN } from '@angularecommerce/core/components/my-account';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MatDialogModule } from '@angular/material/dialog';

export class MyAccountCustomConfig extends Ae2MyAccountGlobalConfig {
  menu: MenuInterface[] = [];
  showFTP: boolean = false;
  constructor() {
    super();
    this.menu.push({ name: 'Meus dados', link: 'meus-dados', icon: 'account_circle' });
    this.menu.push({ name: 'Alterar minha senha', link: 'alterar-senha', icon: 'lock' });
    // this.menu.push({ name: 'Meus endereços', link: 'meus-enderecos', icon: 'room' });
    this.menu.push({ name: 'Cartões de Crédito', link: 'metodos-pagamento', icon: 'credit_card' });
    this.menu.push({ name: 'Meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' });
    this.menu.push({ name: 'Minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' });
    this.menu.push({ name: 'Meus eventos', link: 'meus-eventos', icon: 'event' });
    // this.menu.push({ name: 'Horário Fixo', link: 'meus-eventos-automaticos', icon: 'event' });
    this.menu.push({ name: 'Meus créditos', link: 'meus-creditos', icon: 'account_balance' });
    // this.menu.push({ name: 'Compartilhamento de Créditos', link: 'compartilhar-creditos', icon: 'share' });
    this.menu.push({ name: 'Dados de performance', link: 'dados-de-performance', icon: 'timeline' });
  }
}

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    DialogOverviewDialog
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,

    AppRoutingModule,

    Ae2CoreModule.forRoot({
      useEventStaffMenu: false
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    Ae2AuthStoreModule,
    Ae2AuthVerificationInitializerModule,

    CommonModule,
    HeaderModule,
    FooterModule,
    FlexLayoutModule,
    MenuResponsiveModule,
    HeaderModule,
    FooterModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: Ae2LoginStrategy, useClass: Ae2LoginInPageStrategy },
    { provide: AE2_MY_ACCOUNT_CONFIG_TOKEN, useClass: MyAccountCustomConfig }
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [DialogOverviewDialog]
})
export class AppModule { }
