<!-- <header id="header" [class.change-bg]="isTransparent"> -->
  <header id="header">
    <div class="wrapper" fxLayout>
  
      <div class="bloc1" fxFlex="2 2 auto">
        <a [routerLink]="['/']" class="img-logo" (click)="closeMenu('video')">
          <img id="logoColor" src="/assets/images/main/logo-cor.png" />
        </a>
      </div>
  
      <nav class="bloc2" fxFlex="1 1 auto">
        <ul fxLayout fxLayoutAlign="end" fxLayoutAlign="center center">
          <li (click)="showMenu()" class="p-right"><a fxLayoutAlign="center center">ESTÚDIO<i class="arrowDecorator" [class.arrow-rotate]="submenu">&#xf105;</i></a></li>
          <li (click)="closeMenu()"><a fxLayoutAlign="center center" [routerLink]="['/agenda']">AGENDA</a></li>
          <li (click)="closeMenu()" class="menu-destaque"><a fxLayoutAlign="center center" [routerLink]="['/pacotes/']">PACOTES</a></li>
          <li *ngIf="!isLoggedIn" (click)="closeMenu()"><a fxLayoutAlign="center center" [routerLink]="['/login/']">LOGIN</a></li>
        </ul>
      </nav>
  
      <div *ngIf="isLoggedIn" fxFlex="2 2 100%" class="account-d">
        <div>
          <div class="imgperfil" style.background-image="url({{ (authStore$ | async)?.user.profileImage }})"></div>
          <div class="links-login" *ngIf="(authStore$ | async)?.user.isImpersonate; else impersonateFalse">
            <a>Olá, {{ (authStore$ | async)?.user.impersonatorName }} ({{ (authStore$ | async)?.user.firstName }})</a><br>
            <a routerLink="/minha-conta/meus-dados" (click)="headerColor()">Minha Conta |</a>
            <a href="/impersonate/stop/">Sair</a><br>
            <a routerLink="/checkout" (click)="headerColor()"><i class="fa fa-shopping-cart"></i> Carrinho</a>
          </div>
          <ng-template #impersonateFalse>
            <div class="links-login">
              <a>Olá, {{ (authStore$ | async)?.user.firstName }}</a><br>
              <a routerLink="/minha-conta/meus-dados" (click)="headerColor()">Minha Conta |</a>
              <a href="javascript: void 0" (click)="logOut()">Sair</a><br>
              <a routerLink="/checkout" (click)="headerColor()"><i class="fa fa-shopping-cart"></i> Carrinho</a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </header>
  
  <div class="subheader__bottom" [class.show]="submenu">
      <nav class="header__bottom__navigation" fxLayout fxLayoutAlign="center">
  
          <ae2-activity-list-container [showInActivitiesPage]="true" [active]="true">
              <ng-template #activityListTemplate let-results="results">
                  <button [routerLink]="['/atividade', results[0].slug, results[0].id]" (click)="showMenu('changeHeader')" class="subheader__bottom__navigation__button">ATIVIDADES</button>
              </ng-template>
          </ae2-activity-list-container>
  
          <button (click)="navigator('estudio')" (click)="showMenu()" class="subheader__bottom__navigation__button">UNIDADE VILA OLÍMPIA</button>
          <button (click)="navigator('instrutores')" (click)="showMenu()" class="subheader__bottom__navigation__button">INSTRUTORES</button>
          <button [routerLink]="['/faq']" (click)="showMenu('changeHeader')" class="subheader__bottom__navigation__button">PERGUNTAS FREQUENTES</button>
          <button (click)="navigator('contato')" (click)="showMenu()" class="subheader__bottom__navigation__button">CONTATO</button>
      </nav>
  </div>
  
