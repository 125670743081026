import { Router, NavigationExtras } from '@angular/router';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Ae2RouterClassesConfig } from '@angularecommerce/core/directives/router-classes';
import { ScrollToService } from 'ng2-scroll-to-el';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Ae2AuthState, Ae2AuthLogoutEffectAction, ae2AuthStoreSelector } from '@angularecommerce/core/stores/auth';
import { Ae2AuthStoreService } from '@angularecommerce/core/services/auth-store';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { TranslationService } from 'angular-l10n';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {

  todayDate: Date = new Date();
  submenu: boolean = false;
  config: Ae2RouterClassesConfig;
  // isTransparent: any = false;

  authStore$: Observable<Ae2AuthState>;
  isLoggedIn: boolean;

  private destroy$: Subject<any> = new Subject();

  // @HostListener('window:scroll', [])
  // onWindowScroll(): void {
  //   const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //   const width = window.innerWidth;
  //   if (this.router.url === '/#estudio' || this.router.url === '/#instrutores' ||
  //     this.router.url === '/#contato' || this.router.url === '/' || this.router.url === '') {
  //     if (width >= 991) {
  //       if (number > 35 && this.isTransparent === true) {
  //         this.isTransparent = false;
  //       } else if (number < 35 && this.isTransparent === false) {
  //         this.isTransparent = true;
  //       }
  //     }
  //   }
  // }

  constructor(
    private scrollService: ScrollToService,
    private ae2AuthStoreService: Ae2AuthStoreService,
    private ae2FeedbackService: Ae2FeedbackService,
    private translationService: TranslationService,
    private router: Router,
    store: Store<any>
  ) {
    this.authStore$ = store.pipe(
      select(ae2AuthStoreSelector),
      takeUntil(this.destroy$)
    );

    ae2AuthStoreService.isLoggedIn$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
  }

  ngOnInit(): void {
    // if (this.router.url === '/#estudio' || this.router.url === '/#instrutores' ||
    //   this.router.url === '/#contato' || this.router.url === '' || this.router.url === '/') {
    //   if (this.isTransparent === false) {
    //     this.isTransparent = true;
    //   }
    // }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  showMenu(changeHeader?: string): void {
    this.submenu = !this.submenu;
    // if (changeHeader) {
    //   this.isTransparent = false;
    // }
  }

  closeMenu(video?: string): void {
    this.submenu = false;
    if (video) {
      const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      // if (number === 0) {
      //   this.isTransparent = true;
      // }
    }
    // if (!video) {
    //   if (this.isTransparent === true) {
    //     this.isTransparent = false;
    //   }
    // }
  }

  headerColor(): void {
    // this.isTransparent = false;
    this.submenu = false;
  }

  navigator(element: string): void {
    const navigationExtras: NavigationExtras = {
      fragment: element
    };
    if (this.router.url === '/#estudio' || this.router.url === '/#instrutores' ||
      this.router.url === '/#contato' || this.router.url === '' || this.router.url === '/') {
      setTimeout(() => {
        this.scrollService.scrollTo(`#${element}`);
        this.router.navigate([''], navigationExtras);
      }, 1);
    } else {
      this.router.navigate(['/'], navigationExtras);
      setTimeout(() => {
        this.scrollService.scrollTo(`#${element}`);
      }, 1000);
    }
  }

  logOut(): void {
    this.ae2AuthStoreService.dispatch$(new Ae2AuthLogoutEffectAction())
      .subscribe(() => {
        this.ae2FeedbackService.success({
          message: this.translationService.translate('core.global.messages.users.signOutSuccess'),
          format: Ae2FeedbackFormat.Notification,
          duration: 2000
        });
      })
  }

}
