import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { Ae2MarketingService, Ae2MarketingEmailsAcquired } from '@angularecommerce/core/services/marketing';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { ae2EmailValidatorFn } from '@angularecommerce/core/functions/email-validator';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  form: FormGroup;
  constructor(
    private marketingService: Ae2MarketingService,
    private ae2FeedbackService: Ae2FeedbackService,
    private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService
  ) { }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  submit(): void {
    if (this.form.get('email').value === '') {
      return;
    }
    const data = new Ae2MarketingEmailsAcquired();
    data.email = this.form.get('email').value;
    data.source = 'https://fitbike.com.br/';
    data.campaign = 'newslleter';
    this.marketingService.subscribeUser(data).subscribe(res => {
      if (res.email) {
        this.form.reset();
      }
      this.ae2FeedbackService.success({
        message: 'Email cadastrado com sucesso!',
        format: Ae2FeedbackFormat.Alert
      });

    }, res => {
      this.ae2HttpResponseMessagesService
        .createFeedback(res, Ae2FeedbackFormat.Alert);
    });
  }
  protected createForm(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(6), ae2EmailValidatorFn])
    });
  }


}
