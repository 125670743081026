import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2RouterClassesModule } from '@angularecommerce/core/directives/router-classes';
import { Ae2ActivityListContainerModule } from '@angularecommerce/core/components/activity-list-container';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';

@NgModule({
  imports: [
    MatToolbarModule,
    FlexLayoutModule,
    CommonModule,
    Ae2RouterClassesModule,
    Ae2ActivityListContainerModule,
    Ae2SiteSettingsModule,
    RouterModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }
