import { GaleriaModule } from './../galeria/galeria.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgmCoreModule } from '@agm/core';

import { Ae2ControlMessagesModule } from '@angularecommerce/core/components/control-messages';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { Ae2ArticleModule } from '@angularecommerce/core/components/article';
import { Ae2ArticleSetModule } from '@angularecommerce/core/components/article-set';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';
import { Ae2ActivityListContainerModule } from '@angularecommerce/core/components/activity-list-container';
import { Ae2ContactFormModule } from '@angularecommerce/core/components/contact-form';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';
import { Ae2UnitListContainerModule } from '@angularecommerce/core/components/unit-list-container';
import { Ae2InstructorListContainerModule } from '@angularecommerce/core/components/instructor-list-container';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { GOOGLE_MAPS_KEY } from '../../core/constants/constants';
import { Ae2ReplacePipeModule } from '@angularecommerce/core/pipes/replace/replace.module';

@NgModule({
  imports: [
    HomeRoutingModule,
    Ae2CarouselContainerModule,
    Ae2ArticleModule,
    Ae2ArticleSetModule,
    Ae2SiteSettingsModule,
    Ae2ActivityListContainerModule,
    Ae2ContactFormModule,
    ScrollToModule,
    Ae2ThumborModule,
    Ae2ControlMessagesModule,
    CommonModule,
    Ae2UnitListContainerModule,
    Ae2InstructorListContainerModule,
    GaleriaModule,
    FlexLayoutModule,
    Ae2ReplacePipeModule,
    AgmCoreModule.forRoot({
      apiKey: GOOGLE_MAPS_KEY
    })
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
