<div class="fullscreen layout" id="l-container" *ngIf="!sidenavOpened">
    <mat-sidenav-container>

        <mat-sidenav #sidenav [mode]="sidenavMode" [opened]="sidenavOpened" [position]="sidenavAlign">
            <app-menu-responsive (click)="sidenav.toggle()"></app-menu-responsive>
        </mat-sidenav>

        <div id="sideMenu"> 
 
            <button id="hiddenMyAccount" mat-mini-fab class="l-container_hamBuguer" (click)="sidenav.open()" *ngIf="!sidenavOpened">
                <mat-icon>menu</mat-icon>
            </button>
            <app-header *ngIf="showHeader"></app-header>

            <router-outlet class="sibling-fullscreen"></router-outlet>
 
            <app-footer class="footer" *ngIf="showFooter"></app-footer>

        </div>
 
    </mat-sidenav-container>
 
</div>

<div class="fullscreen layout" id="l-container" *ngIf="sidenavOpened">

    <app-header *ngIf="showHeader"></app-header>

    <div class="fullContent">
        <router-outlet class="sibling-fullscreen"></router-outlet>
    </div>
    <app-footer class="footer" *ngIf="showFooter"></app-footer>
</div>