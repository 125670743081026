import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { ScrollToService } from 'ng2-scroll-to-el';
import { ae2AuthStoreSelector, Ae2AuthLogoutEffectAction, Ae2AuthState } from '@angularecommerce/core/stores/auth';


@Component({
  selector: 'app-menu-responsive',
  templateUrl: './responsive.component.html',
  styleUrls: ['./responsive.component.sass']
})
export class MenuResponsiveComponent implements OnInit {
  isLoggedIn: boolean;
  authStore$: Observable<Ae2AuthState>;

  constructor(protected store: Store<any>, private router: Router, private scrollService: ScrollToService) {
    this.authStore$ = this.store.pipe(select(ae2AuthStoreSelector));
  }

  ngOnInit(): void {
    this.authStore$.subscribe(state => this.isLoggedIn = !!state);
  }
  logOut(): void {
    this.store.dispatch(new Ae2AuthLogoutEffectAction());
  }
  navigator(element: string): void {
    if (this.router.url === '/') {
      setTimeout(() => {
        this.scrollService.scrollTo(`${element}`);
      }, 1);
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        this.scrollService.scrollTo(`${element}`);
      }, 2000);
    }
  }
}
