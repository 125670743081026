<footer fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" class="footer">
  <ae2-site-settings>
    <ng-template ae2SiteSettingsContent let-site="site">
      <div class="footer__top" fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">
        <div class="footer__top__column flex-auto" fxFlex="33%" fxLayout="column">
          <h4 fxLayout="row" class="footer__top__column__title">RECEBA NOSSA NEWSLETTER</h4>
          <form class="form" [formGroup]="form" (submit)="submit($event)">
            <p>EMAIL</p>
            <input formControlName="email">
            <button [disabled]="form.pending || form.invalid">
              <span>OK</span>
            </button>
          </form>
          <p class="cnpj">COPYRIGHT Ride State
            <br> CNPJ 23991671000167
          </p>

          <p class="m-top">
            <a href="https://static.angulare.app/u/ridestate/terms-and-conditions/termos_e_condicoes_mar20_1.pdf" target="_blank" class="termos fabrica">
              Conheça nossos termos e condições
            </a>
          </p>
          <p class="m-top">
            <a routerLink="/politica-de-privacidade" class="termos fabrica">
              Política de Privacidade
            </a>
          </p>

        </div>

        <div class="footer__top__column flex-auto" fxFlex="33%" fxLayout="column">
          <h4 fxLayout="row" class="footer__top__column__title m-t">CONTATO</h4>
          <p class="m-top">{{ site?.phone }}</p>
          <p class="m-top" *ngIf="site?.mobile">{{ site?.mobile }}</p>
          <p>{{ site.address1 }}, {{ site.address2 }}, {{ site.address4 }}, {{ site.city }} - {{ site.state }}</p>
          <p>CEP {{ site.zipCode }}</p>
          <br>
          <p>{{ site?.contactEmail }}</p>
        </div>

        <div class="footer__top__column flex-auto" fxFlex="33%" fxLayout="column">
          <h4 fxLayout="row" class="footer__top__column__title m-t">SIGA-NOS</h4>
          <ul class="footer__top__socials m-top" fxLayout="row">
            <li class="footer__top__socials__item">
              <a [href]="site.socialInstagram" target="_blank" class="footer__top__socials__item__link">
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li class="footer__top__socials__item">
              <a [href]="site.socialFacebook" target="_blank" class="footer__top__socials__item__link">
                <i class="fa fa-facebook fa-1" aria-hidden="true"></i>
              </a>
            </li>
            <li class="footer__top__socials__item">
              <a [href]="site.socialYoutube" target="_blank" class="footer__top__socials__item__link">
                <i class="fa fa-youtube" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
          <div class="footer__top__column__payments">
            <p>Desenvolvido por
              <a class="fabrica" href="https://angulare.app/?utm_source=cws&utm_campaign=ridestate" target="_blank">Angular e-Commerce</a>
              <br> Arte by FIB |
              <a class="fabrica" href="http://www.fabricadeideiasbrasileiras.com.br/" target="_blank"> Fábrica de Ideias Brasileiras</a>
            </p>
            <br>
            <!--
            <p>Powered by
              <u><a class="color" href="http://angular-ecommerce.com.br" target="_blank">Angular e-Commerce</a></u>
            </p>
            -->
          </div>
        </div>
      </div>
    </ng-template>
  </ae2-site-settings>
</footer>