import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule, MatListModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuResponsiveComponent } from './responsive.component';
import { ScrollToModule } from 'ng2-scroll-to-el';


@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    ScrollToModule,
    RouterModule,
    MatIconModule,
    MatListModule
  ],
  declarations: [MenuResponsiveComponent],
  exports: [MenuResponsiveComponent]
})
export class MenuResponsiveModule { }
