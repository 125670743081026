<div class="section" *ngIf="destaque">

    <div class="container-fullw" data-atual="2">
        <a (click)="navRight()" class="seta next js-ctrlinstrutor" data-vai="1"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
        <a (click)="navLeft()" class="seta prev js-ctrlinstrutor" data-vai="-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
        
        <div class="instr-destaque">
            <div class="inst-container ativo" id="inst1-foto" data-array="0" style.background-image="url({{destaque.largeImage | ae2Thumbor:'610x610'}})">
                <div>
                    <h3 class="nome" *ngIf="destaque.nickname == ''">{{ destaque.firstName }} {{ destaque.lastName }}</h3>
                    <h3 class="nome" *ngIf="destaque.nickname != ''">{{ destaque.nickname }}</h3>
                </div> 
            </div>
        </div>
        
        <div class="instr-resto">
            <ul class="lista-instr" *ngIf="total != 0">  
                <ng-container *ngFor="let inst of instrutores, let i = index">
                    <li class="instr-single" (click)="randonChange(inst)" style.background-image="url({{inst.largeImage | ae2Thumbor:'610x610'}})">
                        <div class="inst-color" id="inst2-fotoalt" style="background-image: url(/static/img/filtro-grad.png);">
                            <h3 id="inst2-nome" *ngIf="inst.nickname == ''">{{ inst.firstName }} {{ inst.lastName }}</h3>
                            <h3 id="inst2-nome" *ngIf="inst.nickname != ''">{{ inst.nickname }}</h3>
                        </div>
                    </li>  
                </ng-container>
            </ul>
        </div>


        <div class="instr-info">
            <div class="instr-info-esq">
                <div id="textodestaque">
                    <i class="arrowDecorator">&#xf105;</i>
                    <p class="nameD" *ngIf="destaque.nickname == ''"><strong>{{ destaque.firstName }} {{ destaque.lastName }}</strong></p>
                    <p class="nameD" *ngIf="destaque.nickname != ''"><strong>{{ destaque.nickname }}</strong></p>
                    <br>
                    <p class="textD" [innerHtml]="destaque.shortBio"></p>
                </div>
            </div>          
        </div>
    </div>
</div>