<ae2-carousel-container class="banner-mobile" carouselCode="home1" [config]="carouselConfig"></ae2-carousel-container>
<ng-container *ngIf="isVideo">
  <div class="section-home-topo">

    <div class="video-container">
      <ae2-carousel-container carouselCode="video" [config]="carouselConfig" (data)="onCarouselData($event)">
      </ae2-carousel-container>
    </div>

    <div class="content-video">

      <img src="/assets/images/home/logo-grande.png">

      <!-- <div (click)="volume()" class="sound">
      <i [class.d-block]="turnOn" class="fa fa-volume-up on" aria-hidden="true"></i>
      <i [class.d-block]="!turnOn" class="fa fa-volume-off off" aria-hidden="true"></i>
    </div> -->
      <a class="ico-scroll">
        <i (click)="navigator('#apresentacao')" class="fa fa-angle-down d-block" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</ng-container>

<ae2-article code="home-bloco2" id="apresentacao">
  <ng-template ae2ArticleContent let-title="title" let-description="description">
    <div class="conteiner-article" id="apresentacao" #container>
      <h3 class="tit-home" pageScroll>{{ title }}</h3>
      <hr>
      <p class="subt-article" [innerHtml]="description"></p>

      <a [routerLink]="['/agenda']" class="home-link-login">ESCOLHA SUA AULA</a>

      <ae2-site-settings>
        <ng-template ae2SiteSettingsContent let-site="site">
          <ul fxLayout class="home-apresenta-social">
            <li><a href="{{ site.socialInstagram }}" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li><a href="{{ site.socialFacebook }}" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="{{ site.socialYoutube }}" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
          </ul>
        </ng-template>
      </ae2-site-settings>
    </div>
  </ng-template>
</ae2-article>

<!--<div class="enjoy">
  <h1>#ENJOYTHERIDE</h1>
</div>-->

<div class="section-home-frase enjoy">
  <div class="container">
    <h1>#ENJOYTHERIDE</h1>
  </div>
</div>


<div class="section section-home-atividades" id="atividades">
  <div class="container-fullw">
    <h2 class="home-atividades-titulo">Atividades</h2>
  </div>
</div>

<ae2-activity-list-container [showInActivitiesPage]="true" [active]="true">
  <ng-template #activityListTemplate let-results="results">
    <div fxLayout="row wrap" class="activity_bg">
      <div fxFlex="50%" fxFlex.xs="auto" *ngFor="let item of results" class="home-ativ" style.background-image="url({{item.mediumImage | ae2Thumbor:'960x750'}})">
        <div class="home-ativ-container">
          <div class="centro-home-ativ">
            <h3>{{ item.name }}</h3>
            <span class="ativ-dest"><span (click)="filterRedirect(item.id)" [routerLink]="['/agenda']">Agende uma aula</span></span>
          </div>
          <a class="ativ-svg" [routerLink]="['/atividade', item.slug, item.id]">Saiba +</a>
        </div>
      </div>
      <ng-container *ngIf="!(results.length % 2 == 0)">
        <div class="imparCustom" fxFlex="50%" fxFlex.xs="auto">
          <h3 class="imparCustom-title">Unindo as fibras do corpo<br> às conexões da mente</h3>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ae2-activity-list-container>


<!-- <div class="section-home-frase">
  <div class="container">
    <h3 id="estudio">Unindo as fibras do corpo às<br>conexões da mente</h3>
  </div>
</div> -->

<h3 class="tit-home m-2" id="estudio">ESTÚDIO VILA OLÍMPIA</h3>
<ae2-unit-list-container>
  <ng-template #unitListTemplate let-results="results">
    <div class="end-container imgs_estudio">
      <ae2-carousel-container carouselCode="2" [config]="estudiocarouselConfig">
        <ng-template ae2CarouselOverlayContent let-callToActionText="callToActionText" let-callToActionUrl="callToActionUrl">
          <div class="ae2Carousel__overlay-content-default">
            <a [href]="callToActionUrl">{{ callToActionText }}</a>
          </div>
        </ng-template>
      </ae2-carousel-container>

      <div class="txt-estudio">
        <ae2-site-settings>
          <ng-template ae2SiteSettingsContent let-site="site">
            <p class="txt_estudio_info" [innerHTML]="quebra(site.description)"></p>
          </ng-template>
        </ae2-site-settings>
      </div>
    </div>

    <div class="end-container mapa_resp" fxLayout>
      <div class="map-endereco">
        <ae2-site-settings>
          <ng-template ae2SiteSettingsContent let-site="site">
            <div>
              <small>ENDEREÇO</small>
              <p>{{ site.address }}<br> CEP {{ site.zipCode }}</p><br>
              <small>TELEFONE</small>
              <p>{{ site.phone }}</p>
              <p *ngIf="site?.mobile">{{ site?.mobile }}</p>
            </div>
          </ng-template>
        </ae2-site-settings>
      </div>
      <div class="mapImage" *ngIf="results[1]?.mapImage" [ngStyle]="{'background-image': 'url(' + results[1]?.mapImage + ')'}"></div>
      <!-- <agm-map (click)="liberaMapa()" [latitude]="lat" [longitude]="lng" [disableDefaultUI]="true" [scrollwheel]="libera"
        [zoom]="16" [mapTypeControl]="true">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      </agm-map> -->
    </div>
  </ng-template>
</ae2-unit-list-container>

<h3 class="tit-home m-2 tit-inst" id="instrutores">INSTRUTORES</h3>

<ae2-instructor-list-container [active]="true" [showInInstructorsPage]="true">
  <ng-template #instructorListTemplate let-results="results">
    <app-galeria [instructors]="results"></app-galeria>
  </ng-template>
</ae2-instructor-list-container>

<div class="section-home-frase">
  <div class="container">
    <h3>O MOVIMENTO É UM<br>ESTADO DE ESPIRITO</h3>
  </div>
</div>

<ae2-article-set tag="avaliacao" active="true">
  <ng-template #articleSetTemplate let-results="results">
    <div class="logos-bg">
      <div class="logos-container fix-flex" fxLayout fxLayoutAlign="center center">
        <div class="logo-individual" fxLayout="column" fxFlex="33.33%" fxLayoutAlign="center center" *ngFor="let item of results">
          <a href="{{ item.link }}" target="_blank"><img class="avaliacao-img" src="{{ item.image }}"></a>
          <img class="img-rating" src="/assets/images/home/rating.png">
        </div>
      </div>
    </div>
  </ng-template>
</ae2-article-set>

<!-- <div class="section-home-frase change-bg">
  <div class="containera">
    <h3>APOIADORES</h3>
  </div>
  <div class="apoiadores" fxLayout fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="center center">
    <img fxFlex="18%" fxFlex.xs="auto" src="/assets/images/apoiadores/johnsons.png"> 
    <img fxFlex="10%" fxFlex.xs="auto" src="/assets/images/apoiadores/ob.png">
    <img fxFlex="17%" fxFlex.xs="auto" src="/assets/images/apoiadores/sempre-livre.png">
    <img fxFlex="17%" fxFlex.xs="auto" src="/assets/images/apoiadores/carefree.png">
    <img fxFlex="17%" fxFlex.xs="auto" src="/assets/images/apoiadores/listerine.png">
    <img fxFlex="17%" fxFlex.xs="auto" src="/assets/images/apoiadores/ogx.png">
  </div>
</div> -->

<div class="contact-container" id="contato">
  <div>
    <h3 class="fale">FALE CONOSCO</h3>

    <ae2-contact-form code="contato" subject="Contato via website" [fields]="{
          name: { required: true },
          email: { required: true, validEmail: true },
          phone: { required: true },
          message: { required: true }
      }">

      <ng-template #contactFormTemplate let-form="form" let-methods="methods" let-recaptchaKey="recaptchaKey">
        <form [formGroup]="form" (submit)="methods.submit($event)">
          <div fxLayout fxLayout.xs="column" class="fix-flex">
            <div class="txt-input" fxFlex="6 6 100%">
              <label>NOME</label>
              <input class="contact-input" formControlName="name">
              <ae2-control-messages [control]="form.get('name')"></ae2-control-messages>

              <br>
              <label>EMAIL</label>
              <input class="contact-input" formControlName="email">
              <ae2-control-messages [control]="form.get('email')"></ae2-control-messages>

              <br>
              <label>ASSUNTO</label>
              <input class="contact-input" formControlName="phone">
              <ae2-control-messages [control]="form.get('phone')"></ae2-control-messages>
            </div>

            <div class="msg-input" fxFlex="4 4 85%">
              <label>MENSAGEM</label>
              <textarea class="contact-textarea" formControlName="message"></textarea>
              <ae2-control-messages [control]="form.get('message')"></ae2-control-messages>
            </div>
          </div>
          <br>
          <!-- [disabled]="form.invalid" -->
          <div fxLayout>
            <input class="contact-submit" type="submit" value="ENVIAR">
          </div>
        </form>
      </ng-template>
    </ae2-contact-form>
  </div>
</div>
