import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { Ae2UnauthRouteGuardService, Ae2UnauthRouteGuardServiceModule } from '@angularecommerce/core/services/unauth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
import { HomeModule } from './features/home/home.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  // {
  //   path: 'atividades',
  //   loadChildren: 'app/features/activities/activities.module#ActivitiesModule'
  // },

  {
    path: 'online/class',
    loadChildren: 'app/features/online-class/online-class.module#OnlineClassModule'
  },

  {
    path: 'online/thank-you',
    loadChildren: 'app/features/online-class-thanks/online-class-thanks.module#OnlineClassThanksModule'
  },

  {
    path: 'online/already-connected',
    loadChildren: 'app/features/online-class-disconnected/online-class-disconnected.module#OnlineClassDisconnectedModule'
  },

  {
    path: 'atividades/:activitySlug/:eventSlug/:eventToken',
    loadChildren: 'app/features/event/event.module#EventModule'
  },

  {
    path: 'atividade/:slug/:id',
    loadChildren: 'app/features/activity-detail/activity-detail.module#ActivityDetailModule'
  },
  {
    path: 'instrutores',
    loadChildren: 'app/features/instructors/instructors.module#InstructorsModule'
  },
  {
    path: 'instrutor/:slug/:id',
    loadChildren: 'app/features/instructor/instructor.module#InstructorModule'
  },
  {
    path: 'studios',
    loadChildren: 'app/features/studios/studios.module#StudiosModule'
  },
  {
    path: 'studio/:slug/:id',
    loadChildren: 'app/features/studios-detail/studios-detail.module#StudiosDetailModule'
  },
  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule'
  },
  {
    path: 'login',
    canActivate: [Ae2UnauthRouteGuardService],
    loadChildren: 'app/features/login/login.module#LoginModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'pacotes',
    loadChildren: 'app/features/products/products.module#ProductsModule'
  },
  {
    path: 'faq',
    loadChildren: 'app/features/faq/faq.module#FaqModule'
  },
  {
    path: 'categoria/:categoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule'
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule'
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule'
  },

  {
    path: 'clientes/resetar-senha/:uidb36/:token',
    loadChildren: 'app/features/forgot-password/forgot-password.module#ForgotPasswordModule'
  },

  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot-password/forgot-password.module#ForgotPasswordModule'
  },
  // {
  //   path: 'termos',
  //   loadChildren: 'app/features/termos/termos.module#TermosModule'
  // },
  {
    path: 'politica-de-privacidade',
    loadChildren: 'app/features/politica/politica.module#PoliticaModule'
  },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule'
  },

  {
    path: 'checkout',
    // canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule'
  },

  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    HomeModule,
    Ae2AuthRouteGuardServiceModule,
    Ae2UnauthRouteGuardServiceModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
